import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { InlineWidget } from "react-calendly";
import Link from "gatsby-link";
import { data } from "autoprefixer";
var Recaptcha = require("react-recaptcha");
export default function Example() {
  const [value, setValue] = useState(null);
  const [token, setToken] = useState(null);


  const [select, setSelect] = useState(null);
  const [address, setAddress] = useState();
  
  useEffect(() => {
    setValue("");
  });
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../../images/book.jpg"
            alt="Contact Us"
            placeholder="fixed"
            layout="fullWidth"
            imgStyle={{ overflow: "hidden" }}
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            style={{ overflow: "hidden" }}
            border="5px"
            // You can optionally force an aspect ratio for the generated image
            aspectRatio={1 / 1}
            // This is a presentational image, so the alt should be an empty string
            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-2 lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
          <InlineWidget url="https://calendly.com/d/dxx-h35-w3z/accounting-services-consultation" styles={{height: "1100px", overflowY: "hidden", overflowX: "hidden", }} />
          </div>
        </div>
      </div>
    </div>
  );
}
