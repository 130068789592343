/* This example requires Tailwind CSS v2.0+ */

import React from "react";


import Header from "../components/Header/Header";
import Intro from "../page-sections/Booking/Intro";
import Footer from "../components/Footer/Footer";
import SEO from "../components/SEO/Booking/SEO"

export default function Example() {
  return (
    <div>
      <Header />
      <SEO/>
      <Intro />
      <Footer />
    </div>
  );
}
